import axios from "axios"
import MultiGroupSelection from "common/GroupSelector"
import { convertPermission } from "permissionUtils"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { Input } from "reactstrap"

const FilterReports = ({
  filteredValues,
  statusOptions,
  setFilteredValues,
}) => {
  const [users, setUsers] = useState([])
  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
    }

    axios
      .post("https://rd0.cpvarabia.com/api/usercolist.php", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        let array = res.data
        array = array.filter(item => item !== false)
        console.log("array", array)
        setUsers(array)
      })
      .catch(error => {
        console.log(error)
      })
  }, [])
  return (
    <div className="d-flex justify-content-center col-12">
      <div className="d-flex col-4 justify-content-start mb-2 mx-1">
        <div className="search-box me-4 col-12 ">
          <div className="position-relative mb-2 d-flex">
            <Input
              type={"text"}
              className="form-control border-dark"
              placeholder={"ReferenceNo."}
              onChange={e => {
                let updatedObject = {}
                updatedObject["ReferenceNo"] = e.target.value
                // : (updatedObject[feature] = e.target.value)

                setFilteredValues({
                  ...filteredValues,
                  ...updatedObject,
                })
              }}
            />
            <i className="bx bx-search-alt search-icon " />
          </div>
        </div>
      </div>

      <div className="d-flex col-4 justify-content-start mb-2 mx-1">
        <div className="search-box me-4 col-12 ">
          <div className="position-relative mb-2 d-flex">
            <Input
              type="select"
              name="status"
              onChange={e => {
                setFilteredValues({
                  ...filteredValues,
                  Status: e.target.value,
                })
              }}
              className="form-control border-dark"
            >
              <option selected value="">
                Status...
              </option>
              {statusOptions.map((status, i) => (
                <option key={i} value={status.value}>
                  {status.label}
                </option>
              ))}
            </Input>
            <i className="bx bx-search-alt search-icon " />
          </div>
        </div>
      </div>

      {/* <div className="d-flex col-2 justify-content-start mb-2 mx-1">
        <div className="search-box me-4 col-12 ">
          <div className="position-relative mb-2 d-flex">
            <Input
              type={"text"}
              className="form-control border-dark"
              placeholder={"Inspector."}
              onChange={e => {
                let updatedObject = {}
                updatedObject["Inspector"] = e.target.value
                // : (updatedObject[feature] = e.target.value)
                setFilteredValues({
                  ...filteredValues,
                  ...updatedObject,
                })
              }}
            />
            <i className="bx bx-search-alt search-icon " />
          </div>
        </div>
      </div> */}

      {/* ******************** Inspector *************************** */}
      <div className="search-box me-3 col-4 p-0">
        <div className="position-relative mb-3 form-control border-dark py-0">
          <i className="bx bx-search-alt search-icon my-auto" />
          <MultiGroupSelection
            options={users}
            name="Inspector"
            isMulti={true}
            filteredValues={filteredValues}
            setFilteredValues={setFilteredValues}
          />
        </div>
      </div>
    </div>
  )
}

export default FilterReports
