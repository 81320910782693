import React, { useEffect, useState } from "react"
import { Button, Col, FormGroup, Input, Row } from "reactstrap"
import AddARemove from "./AddARemove"
import MultiSelectInspection from "./MultiSelectInspectionrd0"
import axios from "axios"
import CommentOtherMultiSelect from "./CommentOtherMultiSelect"
import { useHistory } from "react-router-dom"
import DragImages from "common/DragImages/DragImages"
import ImageFullOptions from "common/ImageFullOptions"

const OtherMultiSelect = ({ props, Qitem, dataAnswers, answers, setAuth }) => {
  const history = useHistory()
  const [answerIds, setAnswerIds] = useState([1]) //for creation answers ID

  let numberofIds = []
  // answers &&
  useEffect(() => {
    answers &&
      (answers.map((answer, index) => numberofIds.push(index + 1)),
      setAnswerIds(numberofIds))
  }, [answers])

  const [TRSelectIds, setTRSelectIds] = useState([])
  let fourComment = ["1", "2", "5"]

  const [degreeValue, setDegreeValue] = useState()
  const [degreeList, setDegreeListValue] = useState([])
  const [transferList, setTransferListValue] = useState([])
  const [transferValue, setTransferValue] = useState()

  useEffect(() => {
    let RDIssuer = { ResourceID: "201", Name: "Inspection" }
    axios
      .post("https://rd0.cpvarabia.com/api/inspection/DegreeList.php", {
        PerToken: localStorage.getItem("token"),
        PerUserID: localStorage.getItem("id"),
        PerRoleID: localStorage.getItem("userRoleID"),
        Category: "Design",
      })
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        let resData = res.data
        let data = Object.entries(resData).filter(item => {
          if (item[0] !== "4") return Number(item[0])
        })

        setDegreeListValue(data)
      })

    axios
      .post("https://rd0.cpvarabia.com/api/inspection/TransferToList.php", {
        PerToken: localStorage.getItem("token"),
        PerUserID: localStorage.getItem("id"),
        PerRoleID: localStorage.getItem("userRoleID"),
      })
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        let resData = res.data
        resData[200] = RDIssuer
        let data = Object.entries(resData).filter(item => {
          return Number(item[0])
        })
        setTransferListValue(data)
      })
  }, [dataAnswers])

  // ticketImagie
  const [viewImageFullOptions, setViewImageFullOptions] = useState(false)
  const toggleImageFullOptions = () => {
    setViewImageFullOptions(!viewImageFullOptions)
  }
  const [answerId,setAnswerId]=useState(1)
  
  

  return (
    <div>
      {answerIds.map((id, index) => (
        <Row
          key={id}
          id={id}
          className="d-flex col-12 justify-content-between mt-1"
        >
          {/* comments */}
          {props.values[`${Qitem.AnswerType}${Qitem.QID}-RSelect${id}`] ? (
            <Col id={"TR" + id} sm={6}>
              {/* <FormGroup row> */}
              {
                <CommentOtherMultiSelect
                  userSelect={true}
                  commentsNumber={
                    fourComment.includes(
                      props.values[
                        `${Qitem.AnswerType}${Qitem.QID}-RSelect${id}`
                      ]
                    )
                      ? 4
                      : 2
                  }
                  AnswerType={Qitem.AnswerType}
                  QID={Qitem.QID}
                  classNameDiv="d-flex  mb-2  mt-2"
                  className=" d-flex col-6 me-3 mb-1  form-control"
                  props={props}
                  name={"comments"}
                  // placeholder={
                  //   degreeValue.Name ? degreeValue.Name : degreeValue
                  // }
                  id={id}
                />
              }

              {/* ******************* error handling ******************** */}
              {props.errors[`${Qitem.AnswerType}${Qitem.QID}-Term${id}`] ? (
                <div
                  className="error col-10 mx-2 rounded"
                  style={{
                    marginInlineStart: "5px",
                    fontSize: "12px",
                  }}
                >
                  {props.errors[`${Qitem.AnswerType}${Qitem.QID}-Term${id}`]}
                </div>
              ) : null}
            </Col>
          ) : (
            <Col sm={6}>
              <Input
                type="textarea"
                className="form-control  "
                name={`${Qitem.AnswerType}${Qitem.QID}-Answer${id}`}
                value={
                  props.values[`${Qitem.AnswerType}${Qitem.QID}-Answer${id}`]
                }
                onChange={props.handleChange}
                onBlur={props.handleBlur}
              />

              {/* ******************* error handling ******************** */}
              {props.errors[`${Qitem.AnswerType}${Qitem.QID}-Answer${id}`] ? (
                <div
                  className="error col-12 mx-2 rounded"
                  style={{
                    marginInlineStart: "5px",
                    fontSize: "12px",
                  }}
                >
                  {props.errors[`${Qitem.AnswerType}${Qitem.QID}-Answer${id}`]}
                </div>
              ) : null}
            </Col>
          )}
          {/*degree & transfer select option */}
          <Col className="ms-4">
            <div
              className="  d-flex flex-column align-items-center "
              disabled={
                props.values[`${Qitem.AnswerType}${Qitem.QID}-RSelect${id}`] &&
                true
              }
            >
              <MultiSelectInspection
                props={props}
                id={id}
                data={degreeList}
                name={`${Qitem.AnswerType}${Qitem.QID}-RSelect`}
                title={"Degree"}
                setValue={setDegreeValue}
                className={"me-1"}
                disabled={
                  props.values[
                    `${Qitem.AnswerType}${Qitem.QID}-CSelect${id}`
                  ] && true
                }
              />

              {/* ******************* error handling ******************** */}
              {props.errors[`${Qitem.AnswerType}${Qitem.QID}-RSelect${id}`] ? (
                <div
                  className="error col-12 mx-2 rounded"
                  style={{
                    marginInlineStart: "5px",
                    fontSize: "12px",
                  }}
                >
                  {props.errors[`${Qitem.AnswerType}${Qitem.QID}-RSelect${id}`]}
                </div>
              ) : null}
            </div>

            <div className=" d-flex flex-column align-items-center ">
              <MultiSelectInspection
                props={props}
                id={id}
                data={transferList}
                setValue={setTransferValue}
                name={`${Qitem.AnswerType}${Qitem.QID}-CSelect`}
                title={"Transfer"}
                className={"me-1 "}
                disabled={
                  props.values[
                    `${Qitem.AnswerType}${Qitem.QID}-RSelect${id}`
                  ] && true
                }
              />

              {/* ******************* error handling ******************** */}
              {props.errors[`${Qitem.AnswerType}${Qitem.QID}-CSelect${id}`] ? (
                <div
                  className="error col-12 mx-2 rounded"
                  style={{
                    marginInlineStart: "5px",
                    fontSize: "12px",
                  }}
                >
                  {props.errors[`${Qitem.AnswerType}${Qitem.QID}-CSelect${id}`]}
                </div>
              ) : null}
            </div>
          </Col>
          {/* image */}
          <Col sm={2}>
            <Button onClick={()=>(setAnswerId(id),toggleImageFullOptions())}>Attached Files</Button>
          </Col>
          {/* add & remove answer */}
          <Col sm={2} className="d-flex mt-1">
            <AddARemove
              answerIds={answerIds}
              setAnswerIds={setAnswerIds}
              id={id}
              name={[
                `${Qitem.AnswerType}${Qitem.QID}-Answer${id}`,
                `${Qitem.AnswerType}${Qitem.QID}-RSelect${id}`,
                `${Qitem.AnswerType}${Qitem.QID}-CSelect${id}`,
                `${Qitem.AnswerType}${Qitem.QID}-Term${id}`,
              ]}
              props={props}
            />
          </Col>
        </Row>
      ))}

  { viewImageFullOptions&&   <ImageFullOptions
        viewImageFullOptions={viewImageFullOptions}
        toggleImageFullOptions={toggleImageFullOptions}
        props={props}
        name={`${Qitem.AnswerType}${Qitem.QID}-Image${answerId}`}
        target={"Image"}
        setAuth={setAuth}
        value={props.values[`${Qitem.AnswerType}${Qitem.QID}-Image${answerId}`]}
      />}
    </div>
  )
}

export default OtherMultiSelect
