import React, { useState, useEffect } from "react"
import { UncontrolledTooltip } from "reactstrap"
import CreateQuestion from "../QuestionBuilder/QuestionBuilder"
import { Table } from "antd"
import "antd/dist/antd.css"
import { Link } from "react-router-dom"
import axios from "axios"
import ChildTable from "./childTable"
import EditQuestion from "./EditQuestion"
import { convertPermission } from "permissionUtils"
import { useHistory } from "react-router-dom"
import UnAuthorizedComponent from "common/UnAuthorizedComponent"
import Breadcrumb from "components/Common/Breadcrumb"
import ConfirmingAction from "common/ConfirmingAction"

const Icons = ({ record, QuestionData, userPermissions, setAuth }) => {
  const history = useHistory()

  const [dpopup, setdPopup] = useState(false)
  const deletefunc = () => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      QID: record.QID,
    }
    axios
      .post("https://rd0.cpvarabia.com/api/DeleteQuestion", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

       
        window.location.reload(false)
      })

    setdPopup(!dpopup)
  }

  const toggledPopup = () => {
    setdPopup(!dpopup)
  }
  const DeleteFun = e => {
    toggledPopup()
  }

  //-----------Edit Question-------------------//

  const [editToggle, setEditToggle] = useState(false)
  const [editedQuestion, setEditedQuestion] = useState(null)

  const editToggleHandler = () => {
    setEditToggle(!editToggle)
  }

  const editQuestionHandler = () => {
    editToggleHandler()
    setEditedQuestion(
      QuestionData.filter(question => question.QID == record.QID)[0]
    )
  }

  return (
    <>
      <div className="d-flex justify-content-center">
        {(userPermissions.R1.P === "3" || userPermissions.R1.P === "4") && (
          <Link to="#" className="text-primary">
            <i
              onClick={editQuestionHandler}
              className="mdi mdi-pencil font-size-18"
              id="edittooltip"
            />
            <UncontrolledTooltip placement="top" target="edittooltip">
              Edit
            </UncontrolledTooltip>
          </Link>
        )}

        {userPermissions.R1.P === "4" && (
          <Link
            to="#"
            className="text-danger ms-1"
            onClick={e => {
              console.log("dsssss")
              DeleteFun(e)
            }}
          >
            <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
            <UncontrolledTooltip placement="right" target="deletetooltip">
              Delete
            </UncontrolledTooltip>
          </Link>
        )}

        {/********icons pop *******/}
        {dpopup && (
          <ConfirmingAction
            confirmFunc={deletefunc}
            action={"delete"}
            Confirmetoggle={toggledPopup}
            ConfirmeModel={dpopup}
            massege={"Are you sure you want to delete this question ?"}

            // toggledPopup={() => {
            //   toggledPopup()
            // }}
            // deletefunc={() => {
            //   deletefunc()
            // }}
            // item={"Question"}
          />
        )}
      </div>
      {/*********** popup edit  Question *****/}
      {editToggle && (
        <EditQuestion
          questiontoggle={editToggleHandler}
          editedQuestion={editedQuestion}
          setAuth={setAuth}
        />
      )}
    </>
  )
}

const ViewSection = props => {
  const stageCategory =
    props.location.state?.stageCategory || localStorage.getItem("stageCategory")

  const sectionType =
    props.location.state?.sectionType || localStorage.getItem("sectionType")

  const sectionName = props.location.state?.sectionName

  // **************** Permissions ******************************
  const userPermissions = convertPermission(
    JSON.parse(localStorage.getItem("roles"))
  )
  // console.log("userPermissions", userPermissions)

  // **************** Authorization ******************************
  const [auth, setAuth] = useState(false)

  const history = useHistory()

  const [QuestionData, setQuestionData] = useState([])
  const id = props.match.params.SectionID
  const handleAnswers = (numberAnswers, item) => {
    let answerArray = []
    for (let i = 0; i < numberAnswers; i++) {
      answerArray.push({
        value: item["OptionTitle" + i],
        id: item["QOID" + i],
        hint: item["AHint" + i],
        operator: item["Operator" + i],
      })
    }
    return answerArray
  }
  const [change, setChange] = useState(false)
  const toggleChange = () => {
    setChange(!change)
  }
  useEffect(() => {
    let questionscollect = []
    if (stageCategory === "Design") {
      const formData = {
        PerToken: localStorage.getItem("token"),
        PerUserID: localStorage.getItem("id"),
        PerRoleID: localStorage.getItem("userRoleID"),
        SectionID: id,
      }
      axios
        .post("https://rd0.cpvarabia.com/api/ShowRD0All", formData)
        .then(questions => {
          if (
            questions.data.error === true &&
            questions.data.message === "Access denied!"
          ) {
            setAuth(true)
            setTimeout(() => {
              history.push("/logout")
              setTimeout(() => {
                history.push("/login")
              }, 1000)
            }, 4000)
          }

          Object.entries(questions.data).forEach(item => {
            if (typeof item[1] === "object") {
              questionscollect.push({
                QTitle: item[1].QTitle,
                QID: item[1].QID,
                Answers: handleAnswers(item[1].OptionsCount, item[1]),
                AnswerType: item[1].AnswerType,
                QHint: item[1].QHint,
                ParentAID: item[1].ParentAID,
                Other: item[1].Other,
                QHelp: item[1].QHelp,
                MissingData: item[1].MissingData,
              })
            }
          }) //end of push
          // questionscollect.splice(-1)/
          setQuestionData(questionscollect)
        }) //end of foreach
    }
  }, [change])
  useEffect(() => {
    if (stageCategory === "Inspection") {
      const formData = {
        PerToken: localStorage.getItem("token"),
        PerUserID: localStorage.getItem("id"),
        PerRoleID: localStorage.getItem("userRoleID"),
        SectionID: id,
      }
      axios
        .post(
          "https://rd0.cpvarabia.com/api/InspectionQuestionsView",
          formData
        )
        .then(res => {
          if (
            res.data.error === true &&
            res.data.message === "Access denied!"
          ) {
            setAuth(true)
            setTimeout(() => {
              history.push("/logout")
              setTimeout(() => {
                history.push("/login")
              }, 1000)
            }, 4000)
          }

          // console.log("questions", res.data)
          const questions = Object.values(res.data)
          questions.splice(-1)
          questions.forEach(item => {
            questionscollect.push({
              QTitle: item.Title,
              QID: item.QuestionID,
              AnswerType: item.QType,
            })
          }) //end of push
          setQuestionData(questionscollect)
        }) //end of foreach
    }
  }, [])
  //*************** end of handle Request  *****************/
  const [modal, setmodal] = useState(false)
  const toggle = () => {
    setmodal(!modal)
  }
  const addGroup = () => {
    toggle()
  }
  //-----------createQuestion-------------------//
  const [qToggle, setqToggle] = useState(false)
  const questiontoggle = () => {
    setqToggle(!qToggle)
  }
  const addQuestion = () => {
    questiontoggle()
  }

  //************************ parent Question & Answer table*******************************//

  const PQuestionData = [
    { title: "Question", dataIndex: "QTitle" },
    {
      title: "Action",
      render: record => {
        return (
          <Icons
            record={record}
            QuestionData={QuestionData}
            userPermissions={userPermissions}
            setAuth={setAuth}
          ></Icons>
        )
      },
    },
  ]
  const parentQuestion = []
  // ----------------------------------------------------------------//
  const PAnswer = [{ title: "answer", dataIndex: "answers" }]
  const parentAnswerData = []

  let index = 1
  for (let item in QuestionData) {
    !QuestionData[item].ParentAID &&
      parentQuestion.push({
        key: item,
        QTitle: index++ + "- " + QuestionData[item].QTitle,
        QID: QuestionData[item].QID,
      })

    for (let answer in QuestionData[item].Answers) {
      let answerOperator

      QuestionData[item].Answers[answer].operator
        ? (answerOperator = QuestionData[item].Answers[answer].operator)
        : (answerOperator = " ")
      !QuestionData[item].ParentAID &&
        parentAnswerData.push({
          key: QuestionData[item].Answers[answer].id,
          QID: QuestionData[item].QID,
          answers:
            answerOperator + " " + QuestionData[item].Answers[answer].value,
          answerOperator: QuestionData[item].Answers[answer].operator,
          answerID: QuestionData[item].Answers[answer].id,
        })
    }
  }

  //**************************Child Question & Answer ************************//

  const childQuestion = []

  //----------------------------------------------------------------//
  const childAnswer = []

  for (let item in QuestionData) {
    QuestionData[item].ParentAID &&
      childQuestion.push({
        key: item,
        childQ: QuestionData[item].QTitle,
        ParentAID: QuestionData[item].ParentAID,
        QID: QuestionData[item].QID,
      })
    for (let answer in QuestionData[item].Answers) {
      let answerOperator

      QuestionData[item].Answers[answer].operator
        ? (answerOperator = QuestionData[item].Answers[answer].operator)
        : (answerOperator = " ")
      QuestionData[item].ParentAID &&
        childAnswer.push({
          key: answer,
          QID: QuestionData[item].QID,
          childA:
            answerOperator + " " + QuestionData[item].Answers[answer].value,
          childAOperator: QuestionData[item].Answers[answer].opertator,
          childAID: QuestionData[item].Answers[answer].id,
        })
    }
  }

  //******** end-of-child-question ********/

  // Access denied !!
  if (auth) {
    return <UnAuthorizedComponent />
  }

  // not allowed views
  if (
    userPermissions.R1.P !== "1" &&
    userPermissions.R1.P !== "2" &&
    userPermissions.R1.P !== "3" &&
    userPermissions.R1.P !== "4" &&
    userPermissions.R1.G !== "1"
  )
    return (
      <div className="page-content">
        <h3 className="d-flex justify-content-center p-3 text-light bg-danger">
          You are not authenticated!!
        </h3>
      </div>
    )
  else {
    return (
      <React.Fragment>
        <div className="page-content">
          <div className="col-10 ">
            <Breadcrumb
              title="Sections"
              breadcrumbItem={
                sectionName ? `${sectionName} Section` : "Questions List"
              }
            />

            <div className="mt-5 ms-2 ">
              {(userPermissions.R1.P === "2" ||
                userPermissions.R1.P === "3" ||
                userPermissions.R1.P === "4") && (
                <Link to="#">
                  <button
                    onClick={() => {
                      addQuestion()
                    }}
                    className="btn-primary btn  mb-3 ms-auto p-1"
                  >
                    Add Question
                  </button>
                </Link>
              )}

              {/********************** table ******************************/}
              <div className="">
                {stageCategory === "Design" && (
                  <Table
                    rowClassName={"table-row-light rounded-5"}
                    columns={PQuestionData}
                    dataSource={parentQuestion}
                    expandable={{
                      rowExpandable: record => true,
                      expandedRowRender: record => {
                        let newdata = parentAnswerData.filter(item => {
                          return item.QID == record.QID //condition for answer data
                        })
                        return (
                          <Table
                            columns={PAnswer}
                            pagination={false}
                            dataSource={newdata}
                            showHeader={false}
                            expandable={{
                              //***** child question *******//
                              rowExpandable: record => {
                                for (let i in childQuestion) {
                                  if (
                                    childQuestion[i].ParentAID ==
                                    record.answerID //condition for expand
                                  ) {
                                    return true
                                  }
                                }
                              },
                              expandedRowRender: record => {
                                //******************************** child ************************//

                                return (
                                  <ChildTable
                                    record={record}
                                    QuestionData={QuestionData}
                                    userPermissions={userPermissions}
                                    setAuth={setAuth}
                                  ></ChildTable>
                                )
                              },
                            }}
                          ></Table>
                        )
                      },
                    }}
                  ></Table>
                )}
              </div>
            </div>
          </div>
        </div>

        {/*********** popup create  Question *****/}
        {qToggle && stageCategory === "Design" && (
          <CreateQuestion
            questiontoggle={questiontoggle}
            props={props}
            setAuth={setAuth}
            toggleChange={toggleChange}
          />
        )}
        {/* {qToggle && stageCategory === "Inspection" && (
          <InspectionQuestionBuilder
            questiontoggle={questiontoggle}
            props={props}
            sectionType={sectionType}
          />
          // <CreateInspectionQuestion
          //   questiontoggle={questiontoggle}
          //   props={props}
          //   sectionType={sectionType}
          // />
        )} */}
      </React.Fragment>
    )
  }
}

export default ViewSection
